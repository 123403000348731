import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Subject } from 'rxjs';
import { Product } from 'src/app/services/calculator/models/calculator-product-result-model';
import { enriquecimentoMock, garantiaMock } from './dadosMock';

@Component({
  selector: 'app-detail-product-modal',
  templateUrl: './detail-product-modal.component.html',
  styleUrl: './detail-product-modal.component.scss'
})
export class DetailProductModalComponent implements OnInit {
  @ViewChild(InfiniteScrollDirective)
  infiniteScrollDirective: InfiniteScrollDirective;
  @Output() closeChange = new EventEmitter<void>();
  products: Product[] = [];
  searchValue: string = '';
  productSearch = new Subject<string>();
  titleProductToChange: string;
  typeProduct: number;
  mobileScreen: boolean;
  screenWidth: number;
  totalFilters: number;
  showProductsTotalTags: boolean = false;
  tabIndex = 0;
  enriquecimentos = enriquecimentoMock;
  garantias = garantiaMock;

  constructor() {
    this.getScreenSize();
  }

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: number) {
    this.screenWidth = window.innerWidth;
    this.screenWidth > 576
      ? (this.mobileScreen = false)
      : (this.mobileScreen = true);
  }

  resetScroll() {
    this.infiniteScrollDirective.ngOnDestroy();
    this.infiniteScrollDirective.setup();
  }

  isLink(event: Event): boolean {
    const target = event.target as Element;
    const parent = target.parentElement as Element;

    if (parent.classList.contains('btn-link')) {
      return true;
    }

    return false;
  }

  close() {
    this.closeChange.emit();
  }

  changeTab(index: number) { this.tabIndex = index; }
}
