export const garantiaMock = [
  {
    categoria: 'MACRONUTRIENTES',
    itens: [
      { nome: 'Proteína Bruta (mín)', valor: 26.25, unidade: '/mg' },
      { nome: 'Extrato Etéreo (mín)', valor: 1.4, unidade: '/mg' },
      { nome: 'Fibra Bruta (máx.)', valor: 875.0, unidade: '/mg' },
      { nome: 'Fibra Dietética Total (máx.)', valor: 120.0, unidade: '/mg' },
      { nome: 'Amido (máx.)', valor: 2.0, unidade: '/mg' },
    ],
  },
  {
    categoria: 'MINERAIS',
    itens: [
      { nome: 'Cálcio (máx.)', valor: 77.0, unidade: '/mg' },
      { nome: 'Fósforo (mín)', valor: 0.2, unidade: '/mg' },
      { nome: 'Potássio (mín)', valor: 21000.0, unidade: '/UI' },
    ],
  },
  {
    categoria: 'ÁCIDOS GRAXOS',
    itens: [
      { nome: 'Ômega 6 (mín)', valor: 35.0, unidade: '/mg' },
      { nome: 'Ômega 3 (EPA+DHA) (mín)', valor: 17.5, unidade: '/mg' },
    ],
  },
  {
    categoria: 'OUTROS',
    itens: [
      { nome: 'Matéria Mineral (máx.)', valor: 22.73, unidade: '/mg' },
      { nome: 'Taurina (mín)', valor: 21.0, unidade: '/mg' },
      { nome: 'L-carnitina (mín)', valor: 122.5, unidade: '/mg' },
      { nome: 'β-glucanas (mín)', valor: 203.0, unidade: '/mg' },
      { nome: 'Energia Metabolizável', valor: 875.0, unidade: '/UI' },
      { nome: 'Umidade (mín)', valor: 4.2, unidade: '/mg' },
    ],
  },
];


export const enriquecimentoMock = [
  {
    categoria: 'Vitaminas',
    itens: [
      { nome: 'Ácido fólico', valor: 4.20, unidade: '/mg' },
      { nome: 'Ácido pantotênico', valor: 26.25, unidade: '/mg' },
      { nome: 'Biotina', valor: 1.40, unidade: '/mg' },
      { nome: 'Vitamina A', valor: 21000.00, unidade: '/UI' },
      { nome: 'Vitamina B1', valor: 35.00, unidade: '/mg' },
      { nome: 'Vitamina B2', valor: 122.50, unidade: '/mcg' },
      { nome: 'Vitamina B6', valor: 21.00, unidade: '/mg' },
      { nome: 'Vitamina B12', valor: 122.50, unidade: '/mcg' },
      { nome: 'Vitamina C', valor: 203.00, unidade: '/mg' },
      { nome: 'Vitamina D3', valor: 875.00, unidade: '/UI' },
      { nome: 'Vitamina E', valor: 360.00, unidade: '/UI' },
      { nome: 'Vitamina K3', valor: 2.45, unidade: '/mg' },
      { nome: 'Niacina', valor: 77.00, unidade: '/mg' }
    ]
  },
  {
    categoria: 'Minerais',
    itens: [
      { nome: 'Cobre', valor: 22.73, unidade: '/mg' },
      { nome: 'Ferro', valor: 120.00, unidade: '/mg' },
      { nome: 'Iodo', valor: 2.00, unidade: '/mg' },
      { nome: 'Manganês', valor: 40.00, unidade: '/mg' },
      { nome: 'Selênio	', valor: 0.20, unidade: '/mg' },
      { nome: 'Zinco', valor: 183.99, unidade: '/mg' }
    ]
  },
  {
    categoria: 'Outros Ingredientes',
    itens: [
      { nome: 'Colina', valor: 875.00, unidade: '/mg' },
      { nome: 'Fosfatidilcolina', valor: 12.50, unidade: '/mg' }
    ]
  }
];
