<div class="modal-container">
  <div class="modal-header">
    <h4 class="modal-title">{{ pet ? "Editar" : "Cadastrar" }} Pet</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Fechar"
      (click)="closeModal()"
    >
      <i class="bi bi-x icon__close"></i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="petForm" class="modal-form pet-modal">
      <p class="tutor-info"><span>Tutor:</span> {{ tutorName }}</p>

      <div class="fields">
        <field-validate fieldName="Nome" [showSuccessBackground]="false">
          <input
            onlyText
            maxlength="40"
            type="text"
            formControlName="nome"
            placeholder="Informe o nome do pet"
            (change)="changedFields = true"
          />
        </field-validate>

        @if (pet) {
          <field-validate fieldName="Espécie" [showSuccessBackground]="false">
            <input
              type="text"
              [ngModel]="specieEditPet"
              [ngModelOptions]="{standalone: true}"
              disabled="disabled"
            />
          </field-validate>
        } @else {
          <field-validate fieldName="Espécie" [showSuccessBackground]="false">
            <select
              formControlName="petEspecie"
              (ngModelChange)="loadBreeds($event)"
              (change)="changedFields = true"
            >
              <option disabled [ngValue]="null" selected>Selecione</option>
              <option *ngFor="let specie of species" [value]="specie.id">
                {{ specie.text }}
              </option>
            </select>
          </field-validate>
        }


        <field-validate fieldName="Raça" [showSuccessBackground]="false">
          <select
            (ngModelChange)="loadSizes($event)"
            [attr.disabled]="!breeds.length || pet ? 'disabled' : null"
            formControlName="racaId"
            (change)="changedFields = true; setPetSize()"
          >
            <option disabled [ngValue]="null" selected>Selecione</option>
            <option *ngFor="let breed of breeds" [value]="breed.id">
              {{ breed.name }}
            </option>
          </select>
        </field-validate>

        <field-validate fieldName="Sexo" [showSuccessBackground]="false">
          <select
            [attr.disabled]="pet ? 'disabled' : null"
            formControlName="genero"
            (change)="changedFields = true"
          >
            <option disabled [ngValue]="null" selected>Selecione</option>
            <option [value]="1">Macho</option>
            <option [value]="2">Fêmea</option>
          </select>
        </field-validate>

        <field-validate
          *ngIf="petForm.controls['petEspecie'].value !== '2'"
          fieldName="Porte"
          [showSuccessBackground]="false"
        >
          <select
            [attr.disabled]="
              petForm.controls['racaId'].value === null ? 'disabled' : null
            "
            formControlName="porte"
            (change)="changedFields = true"
          >
            <option disabled [ngValue]="null" selected>Selecione</option>
            <option *ngFor="let size of sizes" [value]="size.size">
              {{ size.sizeName }}
            </option>
          </select>
        </field-validate>

        <field-validate
          class="field-date"
          fieldName="Nascimento"
          [showSuccessBackground]="false"
          (click)="handleMobileBirthDatePicker()"
        >
          <input
            #datepicker
            type="text"
            formControlName="dataNascimento"
            bsDatepicker
            [bsConfig]="bsConfig"
            placeholder="__/__/____"
            (ngModelChange)="changedFields = true"
          />
        </field-validate>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button class="button button-link" (click)="closeModal()">Cancelar</button>
    <button class="button button-primary" (click)="onSubmit()">
      <span>Salvar</span>
      <i class="bi bi-check-circle"></i>
    </button>
  </div>

  @if (showMobileDatePicker) {
    <app-mobile-modal-overlay (closeModalEmitter)="showMobileDatePicker = false">
      <app-mobile-datepicker
        [selectedDate]="petBirthDate"
        (selectedDateEmitter)="setPetBirthDate($event)"
      ></app-mobile-datepicker>
    </app-mobile-modal-overlay>
  }
</div>
